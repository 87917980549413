import styled from 'styled-components';
import FontAwesomeIcon from '../icons/FontAwesomeIcon';

export const Container = styled.button<{ active: boolean }>`
  background-color: ${(props) => (props.active ? props.theme.backgroundLightest : props.theme.background)};
  box-shadow: inset 0 0 0 1px ${(props) => props.theme.keyline};
  height: 56px;
  display: flex;
  flex-direction: row;
  padding: 14px 10px;
  flex: 1;
  align-items: center;
`;

export const Text = styled.span`
  flex: 1;
  font-weight: 600;
  color: ${(props) => props.theme.textDark};
  font-size: 14px;
  text-align: left;
  padding: 0 10px;
`;

export const Pill = styled.span`
  color: ${({ theme }) => theme.white};
  background: ${({ theme }) => theme.primary};
  font-size: 11px;
  font-weight: bold;
  padding: 2px 7px;
  border-radius: 3px;
`;

export const ClearIcon = styled(FontAwesomeIcon)`
  font-size: 14px;
  margin-right: 10px;

  &:hover {
    color: ${({ theme }) => theme.altoGrey};
  }
`;
