import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import useEventListener from '@propertypal/shared/src/hooks/useEventListener';
import React, { CSSProperties, FunctionComponent, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import FontAwesomeIcon from '../icons/FontAwesomeIcon';
import { InputBox, InputPill, InputPills, Placeholder } from './MultiSelect.style';
import { Container, InputLabel, OptionList, OptionItem } from './SelectInput.style';
import ValidationError from './ValidationError';

export interface Option {
  ariaLabel?: string;
  isGroup?: boolean;
  label: string;
  value: string;
}

interface Props {
  id: string;
  containerStyle?: CSSProperties;
  labelStyle?: CSSProperties;
  disabled?: boolean;
  disableMaxHeight?: boolean;
  label?: string;
  options: Option[];
  values: string[];
  onChange: (values: string[]) => void;
  placeholder?: string;
  testID?: string;
  error?: string | false;
  icon?: any;
}

const MultiSelect: FunctionComponent<Props> = (props) => {
  const [active, setActive] = useState(false);
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);
  const optionList = useRef<HTMLUListElement>(null);

  const handleValueChange = (value: string) => {
    const index = props.values.indexOf(value);

    if (index >= 0) {
      const mValues = [...props.values];
      mValues.splice(index, 1);
      props.onChange(mValues);
    } else {
      props.onChange([...props.values, value]);
    }

    setActive(false);
  };

  const handleClick = () => {
    if (!props.disabled) {
      setActive(!active);
    }
  };

  const onOutsideClick = (e: Event) => {
    // @ts-ignore
    if (containerRef.current && e.target && !containerRef.current.contains(e.target)) {
      setActive(false);
    }
  };

  useEventListener('click', onOutsideClick);

  return (
    <Container disabled={props.disabled} ref={containerRef} style={props.containerStyle}>
      {!!props.label && <InputLabel style={props.labelStyle}>{props.label}</InputLabel>}

      <InputBox error={props.error} onClick={handleClick}>
        {props.icon && (
          <FontAwesomeIcon icon={props.icon} color={theme.textDark} style={{ fontSize: 18, marginRight: 10 }} />
        )}

        <InputPills>
          {!props.values.length && <Placeholder>{props.placeholder}</Placeholder>}

          {props.values &&
            props.values.map((value) => {
              const option = props.options.find((o) => o.value === value);

              return (
                <InputPill key={value}>
                  <p>{option?.label}</p>
                  <button onClick={() => handleValueChange(value)} type="button">
                    <FontAwesomeIcon icon={faTimes} color={theme.white} style={{ fontSize: 14 }} />
                  </button>
                </InputPill>
              );
            })}
        </InputPills>

        <FontAwesomeIcon
          icon={faChevronDown}
          color={theme.textDark}
          style={{
            fontSize: 14,
          }}
        />
      </InputBox>

      <OptionList
        active={active}
        disableMaxHeight={props.disableMaxHeight}
        ref={optionList}
        tabIndex={-1}
        data-testid={`${props.testID}ListBox`}
      >
        {props.options
          .filter((option) => props.values.indexOf(option.value) < 0)
          .map((option, index) => (
            <OptionItem
              id={`${props.id}Option${index}`}
              data-testid={`${props.testID}Option${index}`}
              key={option.value}
              isGroup={option.isGroup}
              active={false}
              onClick={!option.isGroup ? () => handleValueChange(option.value) : undefined}
            >
              {option.label}
            </OptionItem>
          ))}
      </OptionList>

      <ValidationError error={props.error} />
    </Container>
  );
};

export default MultiSelect;
