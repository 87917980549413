import { mobileXXLargeMax } from '@propertypal/shared/src/constants/mediaQueries';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${(props) => props.theme.background};
  z-index: 10;
  box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 26px;
  height: 54px;
  overflow: hidden;

  @media print {
    display: none;
  }
`;

export const LinkButton = styled.button<{ $active?: boolean; $hideOnMobile?: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border-right: 1px solid ${(props) => props.theme.backgroundLightest};

  &:last-child {
    border-right: none;
  }

  ${(props) =>
    props.$active &&
    `
    background-color: ${props.theme.primary};
    color: ${props.theme.white};
    border-right: none;
      
    svg, p {
      color ${props.theme.background};
    }

    svg g {
      fill: ${props.theme.background};
    }
  `}

  svg {
    font-size: 22px;
  }

  @media (${mobileXXLargeMax}) {
    padding: 0;
    display: ${({ $hideOnMobile }) => ($hideOnMobile ? 'none' : 'flex')};

    svg {
      font-size: 18px;
    }
  }
`;

export const Text = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: ${(props) => props.theme.textDark};
  margin-left: 10px;
  white-space: nowrap;

  @media (${mobileXXLargeMax}) {
    margin-left: 5px;
  }
`;
