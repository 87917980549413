import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: inline-block;
  text-align: left;
  opacity: 1;
  direction: ltr;
  border-radius: 14px;
  transition: opacity 0.25s ease 0s;
  touch-action: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  user-select: none;
`;

export const Handle = styled.div<{ disabled?: boolean; checked: boolean }>`
  height: 32px;
  width: 32px;
  background: ${({ theme, disabled }) => theme[disabled ? 'backgroundLightest' : 'white']};
  display: inline-block;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translate(${({ checked }) => (checked ? 36 : 4)}px, -50%);
  outline: 0px;
  border: 0px;
  transition:
    background-color 0.25s ease 0s,
    transform 0.25s ease 0s,
    box-shadow 0.15s ease 0s;
`;

export const Input = styled.input`
  border: 0px;
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0px;
  position: absolute;
  width: 1px;
`;

export const Bg = styled.div<{ disabled?: boolean; checked: boolean }>`
  height: 40px;
  width: 72px;
  margin: 0px;
  position: relative;
  background: ${({ checked, theme, disabled }) =>
    disabled ? theme.backgroundMid : theme[checked ? 'primary' : 'lightGrey']};
  border-radius: 20px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: background 0.25s ease 0s;
`;
