import useEventListener from '@propertypal/shared/src/hooks/useEventListener';
import React, { CSSProperties, FunctionComponent, HTMLAttributes, useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import DropButton from '../buttons/DropButton';
import { Container, InputLabel, OptionList, OptionItem, NativeSelect } from './SelectInput.style';
import ValidationError from './ValidationError';

export interface Option {
  ariaLabel?: string;
  isGroup?: boolean;
  label: string;
  value: string;
}

interface Props {
  ariaLabel: string;
  id: string;
  containerStyle?: CSSProperties;
  inputStyle?: CSSProperties;
  labelStyle?: CSSProperties;
  disabled?: boolean;
  disableMaxHeight?: boolean;
  label?: string;
  options: Option[];
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  testID?: string;
  error?: string | false;
  onBlur?: () => void;
  icon?: any;
  className?: HTMLAttributes<HTMLElement>['className'];
  wildcard?: (value: string) => string;
  clearable?: boolean;
}

const getDisplayString = (
  options: Option[],
  currentValue: string,
  placeholder?: string,
  wildcard?: Props['wildcard'],
) => {
  if (placeholder && !currentValue) {
    return placeholder;
  }

  const index = options.findIndex((row) => row.value === currentValue);

  if (index >= 0) {
    return options[index].label;
  }

  if (wildcard && !!currentValue) {
    return wildcard(currentValue);
  }

  return currentValue;
};

const SelectInput: FunctionComponent<Props> = (props) => {
  const [active, setActive] = useState(false);
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const optionList = useRef<HTMLUListElement>(null);

  const handleValueChange = (value: string) => {
    props.onChange(value);
    setActive(false);
  };

  const handleClick = () => {
    if (!props.disabled) {
      setActive(!active);
    }
  };

  const onOutsideClick = (e: Event) => {
    // @ts-ignore
    if (containerRef.current && e.target && !containerRef.current.contains(e.target)) {
      setActive(false);
    }
  };

  useEventListener('click', onOutsideClick);

  useEffect(() => {
    if (active) {
      const index = props.options.findIndex((opt) => opt.value === props.value);

      if (index > -1) {
        const el = document.getElementById(`${props.id}Option${index}`);
        el?.scrollIntoView({ block: 'nearest', inline: 'nearest' });
      }
    }
  }, [active]);

  return (
    <Container disabled={props.disabled} ref={containerRef} style={props.containerStyle} className={props.className}>
      {!!props.label && <InputLabel style={props.labelStyle}>{props.label}</InputLabel>}

      <DropButton
        innerRef={buttonRef}
        containerStyle={{
          width: '100%',
          boxShadow: `inset 0 0 0 ${props.error ? `2px ${theme.primary}` : `1px ${theme.keyline}`}`,
          ...props.inputStyle,
        }}
        textStyle={{
          fontSize: 16,
          fontWeight: 'normal',
          color: props.value ? theme.textDark : theme.backgroundMid,
        }}
        tabIndex={-1}
        active={active}
        testID={props.testID}
        onClick={handleClick}
        onClear={
          props.clearable && props.value
            ? () => {
                handleValueChange('');
                setActive(false);
              }
            : undefined
        }
        text={getDisplayString(props.options, props.value, props.placeholder, props.wildcard)}
        icon={props.icon}
      />

      <NativeSelect
        aria-label={props.ariaLabel || props.label}
        onChange={(e) => handleValueChange(e.target.value)}
        value={props.value}
        onBlur={() => props.onBlur && props.onBlur()}
      >
        {props.placeholder && (
          <option disabled value="">
            {props.placeholder}
          </option>
        )}

        {props.options.map((option) => {
          if (option.isGroup) return null;

          return (
            <option aria-label={option.ariaLabel} key={option.value} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </NativeSelect>

      <OptionList
        active={active}
        disableMaxHeight={props.disableMaxHeight}
        ref={optionList}
        tabIndex={-1}
        data-testid={`${props.testID}ListBox`}
      >
        {props.options.map((option, index) => (
          <OptionItem
            id={`${props.id}Option${index}`}
            data-testid={`${props.testID}Option${index}`}
            key={option.value}
            isGroup={option.isGroup}
            active={option.value === props.value}
            data-active={option.value === props.value}
            onClick={!option.isGroup ? () => handleValueChange(option.value) : undefined}
          >
            {option.label}
          </OptionItem>
        ))}
      </OptionList>

      <ValidationError error={props.error} />
    </Container>
  );
};

export default SelectInput;
