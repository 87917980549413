import styled from 'styled-components';

const filterBreakpoint = 'max-width: 767px';

export const Container = styled.div<{ disabled?: boolean }>`
  position: relative;
  flex: 1;
  ${(props) => (props.disabled ? 'opacity: 0.5;' : '')}
`;

export const InputLabel = styled.label`
  display: block;
  color: ${(props) => props.theme.textDark};
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
`;

export const NativeSelect = styled.select`
  pointer-events: none;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @media (${filterBreakpoint}) {
    pointer-events: auto;
  }
`;

export const OptionList = styled.ul<{ active: boolean; disableMaxHeight?: boolean }>`
  display: none;
  position: absolute;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  background-color: ${(props) => props.theme.background};
  border: 1px solid ${(props) => props.theme.keyline};
  padding: 0;
  margin: -1px 0 0;
  z-index: 90;

  ${(props) =>
    props.active &&
    `
    display: block;
  `}

  ${(props) =>
    props.disableMaxHeight &&
    `
    max-height: calc(100vh - 150px);
  `}
`;

export const OptionItem = styled.li<{ active: boolean; isGroup?: boolean }>`
  list-style: none;
  cursor: pointer;
  text-align: left;
  padding: 12px 15px;
  font-size: 16px;
  color: ${(props) => props.theme.textDark};
  font-weight: ${(props) => (props.active ? '600' : '400')};

  &:hover {
    background-color: ${(props) => props.theme.backgroundLightest};
  }

  &[data-active='true'] {
    background: ${({ theme }) => theme.backgroundLightest};
  }

  ${(props) =>
    props.isGroup &&
    `
    background: ${props.theme.backgroundLighter};
    color: ${props.theme.textLight};
    font-weight: 600;
  `}
`;
