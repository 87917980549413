import styled from 'styled-components';
import FontAwesomeIcon from '../icons/FontAwesomeIcon';

export const Label = styled.label`
  display: block;
  font-size: 16px;
  color: ${(props) => props.theme.textDark};
  margin-bottom: 10px;
  font-weight: 600;
`;

export const Container = styled.div`
  position: relative;
`;

export const Placeholder = styled.p`
  font-size: 16px;
  color: ${(props) => props.theme.backgroundMid};
  margin-bottom: 5px;
`;

export const InputBox = styled.div<{ error?: string | false }>`
  background-color: ${(props) => props.theme.background};
  display: flex;
  min-height: 56px;
  box-shadow: inset 0 0 0 ${(props) => (props.error ? `2px ${props.theme.primary}` : `1px ${props.theme.keyline}`)};
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
`;

export const InputPills = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 5px;
  flex: 1;
`;

export const InputPill = styled.div`
  display: flex;
  align-items: center;
  padding: 2px 5px;
  background-color: ${(props) => props.theme.primary};
  border-radius: 4px;
  margin-right: 4px;
  margin-bottom: 4px;
  color: ${(props) => props.theme.white};
  font-size: 14px;
  font-weight: 600;

  button {
    padding: 5px 0 4px 6px;
  }
`;

export const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
  z-index: 1;
`;
