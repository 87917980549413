import React, { CSSProperties, FunctionComponent, HTMLAttributes } from 'react';
import { Container, Content, Label, Item } from './Toggle.style';

interface Option {
  label: string;
  value: string;
}

interface Props {
  label?: string;
  containerStyle?: CSSProperties;
  value: string;
  options: Option[];
  onChange: (value: string) => void;
  testID?: string;
  className?: HTMLAttributes<HTMLElement>['className'];
}

const Toggle: FunctionComponent<Props> = (props) => {
  return (
    <Container style={props.containerStyle} className={props.className}>
      {!!props.label && <Label>{props.label}</Label>}

      <Content role="radiogroup" aria-label={props.label}>
        {props.options.map((option, index) => (
          <Item
            key={option.value}
            aria-checked={option.value === props.value}
            data-testid={`${props.testID}Option${index}`}
            onClick={() => props.onChange(option.value)}
            active={option.value === props.value}
            role="radio"
            type="button"
          >
            {option.label}
          </Item>
        ))}
      </Content>
    </Container>
  );
};

export default Toggle;
