import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
`;

export const Content = styled.div`
  background-color: ${(props) => props.theme.background};
  border-radius: 8px;
  height: 56px;
  display: flex;
  overflow: hidden;
  flex: 1;
`;

export const Item = styled.button<{ active: boolean }>`
  background: none;
  color: ${(props) => props.theme.textDark};
  border: none;
  flex: 1;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Open Sans', serif;

  ${(props) =>
    props.active &&
    `
    background: ${props.theme.textDark};
    color: ${props.theme.background};
  `}
`;

export const Label = styled.label`
  display: block;
  color: ${(props) => props.theme.primary};
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
`;
