import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CheckBorder = styled.div<{ disabled?: boolean }>`
  position: relative;
  background-color: ${(props) => props.theme[props.disabled ? 'backgroundLightest' : 'background']};
  border: 1px solid ${(props) => props.theme.keyline};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const LogoBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 15px;
`;

export const Logo = styled.img`
  display: block;
  max-width: 90px;
  max-height: 50px;
  margin-right: 10px;
`;

export const Label = styled.label<{ disabled?: boolean; clickable?: boolean }>`
  font-size: 16px;
  font-weight: 600;
  color: ${({ disabled, theme }) => (disabled ? theme.backgroundMid : theme.textDark)};
  cursor: ${({ disabled, clickable }) => (disabled ? 'not-allowed' : clickable ? 'pointer' : 'auto')};
`;

export const HiddenInput = styled.input<{ disabled?: boolean; clickable?: boolean }>`
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  z-index: 2;
  cursor: ${({ disabled, clickable }) => (disabled ? 'not-allowed' : clickable ? 'pointer' : 'auto')};
`;
